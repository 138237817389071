exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-ofm-js": () => import("./../../../src/pages/about-ofm.js" /* webpackChunkName: "component---src-pages-about-ofm-js" */),
  "component---src-pages-ari-js": () => import("./../../../src/pages/ari.js" /* webpackChunkName: "component---src-pages-ari-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-juri-js": () => import("./../../../src/pages/juri.js" /* webpackChunkName: "component---src-pages-juri-js" */),
  "component---src-pages-kusti-js": () => import("./../../../src/pages/kusti.js" /* webpackChunkName: "component---src-pages-kusti-js" */),
  "component---src-pages-lari-js": () => import("./../../../src/pages/lari.js" /* webpackChunkName: "component---src-pages-lari-js" */),
  "component---src-pages-pasi-js": () => import("./../../../src/pages/pasi.js" /* webpackChunkName: "component---src-pages-pasi-js" */),
  "component---src-pages-sami-js": () => import("./../../../src/pages/sami.js" /* webpackChunkName: "component---src-pages-sami-js" */),
  "component---src-pages-tommy-js": () => import("./../../../src/pages/tommy.js" /* webpackChunkName: "component---src-pages-tommy-js" */),
  "component---src-pages-ville-mikko-js": () => import("./../../../src/pages/ville-mikko.js" /* webpackChunkName: "component---src-pages-ville-mikko-js" */)
}

